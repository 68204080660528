/** @jsxImportSource @emotion/react */

import type { VFC } from "react";

import { css } from "@emotion/react";
import { close } from "@PRNDcompany/heydealer-lib/app";
import { Button, colors } from "@PRNDcompany/heydealer-ui";

import { ReactComponent as Zero } from "./assets/zero.svg";

const ContainerStyle = css`
  position: fixed;
  bottom: 0;

  box-sizing: border-box;
  width: 100vw;

  padding: 1rem;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));

  background: ${colors.white_1};

  z-index: 2;
  max-width: 37.5rem;
`;

type 내차팔기버튼Props = {
  deeplinkUrl?: string;
};

const 내차팔기버튼: VFC<내차팔기버튼Props> = ({ deeplinkUrl }) => {
  return (
    <div css={ContainerStyle}>
      <Button
        fullWidth
        onClick={() => {
          setTimeout(() => {
            close();
          });

          window.open(deeplinkUrl);
        }}
        loading={!deeplinkUrl}
      >
        <span css={{ display: "flex", alignItems: "center" }}>
          <Zero css={{ marginRight: "0.25rem" }} /> 로 내차팔기
        </span>
      </Button>
    </div>
  );
};

export default 내차팔기버튼;
