import React from "react";

import { checkAppEnv, isAppWebview } from "@PRNDcompany/heydealer-lib/app";

import PageWrapper from "../../components/PageWrapper/PageWrapper";

import styles from "./TeslaOptionGuidePage.module.scss";

const TeslaOptionGuidePage: React.FC = () => {
  const isOptionReleased =
    isAppWebview &&
    checkAppEnv([
      { os: "ios", type: "customer", version: ">= 6.39.0" },
      { os: "android", type: "customer", version: ">= 6.41.0" },
    ]);

  return (
    <>
      <PageWrapper title="테슬라 옵션 목록 확인방법">
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>테슬라 옵션 목록 확인방법</h1>
            <div className={styles.paragraph}>
              테슬라 공식 사이트에서 로그인하면, 옵션 목록을 확인할 수 있습니다.
              <div className={styles.content}>
                {"· 방법: TESLA 계정 > 내 차 관리 > 상세정보 페이지"}
                <br />
                <a href="https://www.tesla.com/ko_KR/" className={styles.link} target="_blank">
                  · https://www.tesla.com/ko_KR/
                </a>
              </div>
            </div>
          </div>

          <div className={styles.teslaOptionListWrapper}>
            <img src="/tesla_option_list.gif" alt="테슬라 옵션 목록" className={styles.teslaOptionList} />
          </div>
          <div className={styles.body}>
            <div className={styles.paragraph}>
              <div className={styles.subTitle}>확인방법 상세설명</div>
              <div className={styles.list}>
                <div className={styles.listItem}>
                  <span className={styles.number}>1.</span>
                  <span>
                    <a href="https://www.tesla.com/ko_KR/" className={styles.link} target="_blank">
                      https://www.tesla.com/ko_KR/
                    </a>
                    <br />
                    접속 후 오른쪽 상단 메뉴 버튼을 클릭합니다.
                  </span>
                </div>
                <div className={styles.listItem}>
                  <span className={styles.number}>2.</span>
                  <span>
                    메뉴가 열리면 아래로 스크롤 후 <b>TESLA 계정</b>을 클릭합니다.
                  </span>
                </div>
                <div className={styles.listItem}>
                  <span className={styles.number}>3.</span>
                  <span>차량이 등록된 계정으로 로그인 합니다.</span>
                </div>
                <div className={styles.listItem}>
                  <span className={styles.number}>4.</span>
                  <span>
                    차대번호 아래에 있는 <b>[관리]</b> 버튼을 클릭합니다.
                  </span>
                </div>
                <div className={styles.listItem}>
                  <span className={styles.number}>5.</span>
                  <span>
                    차량 이미지 오른쪽 상단에 있는 <b>상세 정보</b>를 클릭합니다.
                  </span>
                </div>
                <div className={styles.listItem}>
                  <span className={styles.number}>6.</span>
                  {isOptionReleased ? (
                    <span>
                      <b>차량 상세 정보</b> 페이지 캡쳐 후 헤이딜러 앱에 업로드 해주세요.
                    </span>
                  ) : (
                    <span>
                      <b>차량 상세 정보</b> 페이지 캡처 후<br />
                      헤이딜러 앱 “채팅문의” 로 전달해주세요.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default TeslaOptionGuidePage;
