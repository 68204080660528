import React, { forwardRef, useEffect, useRef, useState } from "react";

import { close } from "@PRNDcompany/heydealer-lib/app";
import classNames from "classnames";
import { chain, get } from "lodash";
import Ink from "react-ink";

import PageWrapper from "../../components/PageWrapper/PageWrapper";
import useScale from "../../cores/useScale";
import type { Faq as IFaq } from "../../declaration/Faq";

import styles from "./InspectorFAQ.module.scss";

const faqs: IFaq[] = [
  {
    title: "고객 연락이 되지 않아요",
    content: [
      "평가장소에서 고객연락 부재시 가이드입니다.",
      "1. 고객께 전화는 5분 간격으로 해주세요.",
      "2. 약속시간 15분 이상 경과 시, 평가사 카톡방에 메세지 남겨주세요. (본사에서 고객 연락)",
      "3. 약속시간 30분 이상 경과 시, 다음 평가 일정을 고려하여 이동해 주세요.",
    ].join("<br />"),
  },
  {
    title: "경매 및 판매 소요시간",
    content: [
      "(경매 진행시간)",
      "기본 48시간이며, 주말(금~일)은 72시간 동안 진행됩니다.",
      "단, 입찰이 부족한 경우 24시간씩 연장될 수 있습니다.",
      "연휴기간에는 최대 144시간까지 연장될 수 있습니다.",
      "",
      "(판매요청 유효시간)",
      "경매 종료 시점부터 72시간 입니다.",
      "",
      "* 재경매를 진행할 경우, 판매여부 확정까지 최대 3주까지 소요될 수 있습니다.",
    ].join("<br />"),
  },
  {
    title: "고객과의 일정을 마음대로 조정해도 되나요?",
    content: [
      "가능하다면 원래 일정 변경없이 진행하는 것을 권해드립니다.",
      "하지만 방문평가업무 특성상 교통상황과 현장변수 등 예기치 못한 상황을 고려하여, 고객님 동의를 받고 변경하시는 것은 괜찮습니다.",
      "",
      "일정변경은 다음 순서로 해주세요.",
      "\b\b\b1. 고객통화 후 일정 조율",
      "\b\b\b2. 평가사 앱에 변경된 일정 입력",
    ].join("<br />"),
  },
];

const Faq = forwardRef<HTMLDivElement, { data: IFaq }>(({ data }, ref) => {
  const headerRef = useRef<HTMLButtonElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [initialHeight] = useScale(96);
  const [contentHeight, setContentHeight] = useState(initialHeight);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    setContentHeight(contentRef.current.offsetHeight);
  }, [isVisible]);

  return (
    <div
      ref={ref}
      style={{ height: isVisible ? contentHeight + initialHeight : initialHeight }}
      className={classNames(styles.faq, isVisible && styles.isVisible)}
    >
      <button
        ref={headerRef}
        className={styles.title}
        onClick={() => {
          setVisible((prevState) => !prevState);
        }}
      >
        {get(data, "title")}
        <Ink />
      </button>
      <div ref={contentRef} className={styles.content}>
        <div className={styles.inner}>
          <div className={styles.icon} />
          <div className={styles.html} dangerouslySetInnerHTML={{ __html: get(data, "content") }} />
        </div>
      </div>
    </div>
  );
});

const InspectorFAQ: React.FC = () => {
  return (
    <PageWrapper title="자주 묻는 질문 (제휴 평가사)">
      <div className={styles.questionPage}>
        <div className={styles.appBar}>
          <button
            className={styles.backButton}
            onClick={() => {
              close();
            }}
          >
            <Ink />
          </button>
          <h1 className={styles.title}>{"자주 묻는 질문 (제휴 평가사)"}</h1>
        </div>
        <div className={styles.body}>
          {chain(faqs)
            .map((faq, key) => {
              return <Faq key={key} data={faq} />;
            })
            .value()}
        </div>
      </div>
    </PageWrapper>
  );
};

export default InspectorFAQ;
