/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import { useLayoutEffect } from "react";

import { close, isAppWebview, setStatusBar } from "@PRNDcompany/heydealer-lib/app";
import { useRequiredParams } from "@PRNDcompany/heydealer-lib/react-router";
import {
  DeprecatedAppBar as AppBar,
  colors,
  StrokeCloseIcon,
  TitleBar,
  toast,
  Typography,
} from "@PRNDcompany/heydealer-ui";
import { useQuery } from "@tanstack/react-query";

import Page from "../../../components/Page";
import { createGetCarPaperPreparationInfoAPI } from "../apis/cars";

import DocumentListItem from "./components/DocumentListItem";

const BusinessDocumentsPage: FC = () => {
  const { hashId } = useRequiredParams(["hashId"]);

  const { refetch, data: paperPreparationInfo } = useQuery({
    suspense: true,
    queryKey: ["cars", hashId, "paper_preparation_info"],
    queryFn: createGetCarPaperPreparationInfoAPI(hashId),
  });

  useLayoutEffect(() => {
    if (isAppWebview) {
      setStatusBar({ color: colors.white_1, content_brightness: "dark" });
    }
  }, []);

  if (!paperPreparationInfo) {
    return null;
  }

  const { business_document_info, business_documents } = paperPreparationInfo;

  if (business_documents.length === 0) {
    if (isAppWebview) {
      toast.success("제출 완료되었습니다.");
      close();

      return null;
    } else {
      return (
        <Page>
          <TitleBar title={"필요한 서류를\n모두 제출했어요.👍"} />
        </Page>
      );
    }
  }

  return (
    <Page>
      {isAppWebview && (
        <AppBar leftIcon={{ icon: StrokeCloseIcon, onClick: close }} sticky background={`${colors.white_1}E6`} />
      )}
      <div css={{ display: "grid", gap: "0.5rem", padding: "1.25rem 1.5rem" }}>
        <Typography variant="headline_3">
          <div dangerouslySetInnerHTML={{ __html: business_document_info.title_html }} />
        </Typography>
        <Typography variant="body_1">
          <div dangerouslySetInnerHTML={{ __html: business_document_info.description_html }} />
        </Typography>
        {business_document_info.comment_html && (
          <Typography variant="body_2" color="white_7">
            <div dangerouslySetInnerHTML={{ __html: business_document_info.comment_html }} />
          </Typography>
        )}
      </div>
      <div css={{ padding: "1.5rem" }}>
        {business_documents.map((document) => (
          <DocumentListItem document={document} key={`${document.id}`} refetch={refetch} />
        ))}
      </div>
    </Page>
  );
};

export default BusinessDocumentsPage;
