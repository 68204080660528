/** @jsxImportSource @emotion/react */

import type { FC } from "react";
import { useEffect, useState, useRef } from "react";

import { css } from "@emotion/react";
import { ArrowDownIcon, ArrowUpIcon, Haptic, QuestionIcon, Typography } from "@PRNDcompany/heydealer-ui";

import type { FAQ } from "../../customer/apis";

const toggleStyle = css`
  display: grid;
  justify-content: start;
  align-items: flex-start;
  gap: 0.5rem;

  padding: 0.75rem 0;

  grid-template-columns: 1rem 1fr 1rem;
`;

const contentWrapperStyle = css`
  transition: max-height 0.3s ease-in-out;

  overflow: hidden;
`;

const contentAreaStyle = css`
  box-sizing: border-box;

  padding: 0 1.5rem 1rem;
`;

const FAQSection: FC<FAQ> = ({ title, content }) => {
  const contentAreaRef = useRef<HTMLDivElement>(null);
  const [isActive, setActive] = useState(false);
  const [contentWrapperMaxHeight, setContentWrapperMaxHeight] = useState(0);

  const toggleActive = () => setActive((prev) => !prev);

  useEffect(() => {
    setContentWrapperMaxHeight(contentAreaRef.current?.getBoundingClientRect().height ?? 0);
  }, [isActive]);

  return (
    <div>
      <Haptic type="selection" trigger="onClick">
        <div css={toggleStyle} onClick={toggleActive}>
          <div css={{ display: "flex", alignItems: "center", height: "1.75rem" }}>
            <QuestionIcon size={16} color="brand_2" />
          </div>
          <Typography variant={isActive ? "subtitle_2" : "body_1"}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Typography>
          <div css={{ display: "flex", alignItems: "center", height: "1.75rem" }}>
            {isActive ? <ArrowUpIcon size={16} color="white_5" /> : <ArrowDownIcon size={16} color="white_5" />}
          </div>
        </div>
      </Haptic>
      <div css={[contentWrapperStyle, { maxHeight: isActive ? `${contentWrapperMaxHeight}px` : 0 }]}>
        <div ref={contentAreaRef} css={contentAreaStyle}>
          <Typography variant="body_1">
            {/* TODO-5178: 하이퍼링크 클릭 햅틱 여부 확인 필요; https://prnd.slack.com/archives/C07L6L535QR/p1730257901311109?thread_ts=1729668254.536169 */}
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
