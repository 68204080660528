/** @jsxImportSource @emotion/react */
import type { ChangeEventHandler, FC } from "react";
import { useEffect, useState } from "react";

import { css } from "@emotion/react";
import { close, isAppWebview } from "@PRNDcompany/heydealer-lib/app";
import {
  DeprecatedAppBar as AppBar,
  Button,
  colors,
  Divider,
  LocationIcon,
  StrokeCloseIcon,
  TextareaTextfield,
  toast,
  Typography,
} from "@PRNDcompany/heydealer-ui";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";

import Page from "../../../components/Page";
import useScrollPosition from "../../../hooks/useScrollPosition";
import LoadingPage from "../../LoadingPage/LoadingPage";
import { logVisitDetailAddressFormEvent } from "../modules/logEvent";

import { createGetCarAPI, createPatchCarPreInspectionAPI } from "./apis/cars";
import DetailAddressBg from "./assets/bg/DetailAddress.png";

const CTAAreaStyle = css`
  position: sticky;
  top: 100%;

  margin-bottom: calc(-1 * env(safe-area-inset-bottom));

  padding: 1rem;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
`;

const MAX_LENGTH = 80;

const PreInspectionDetailAddressPage: FC = () => {
  const [detailAddress, setDetailAddress] = useState("");
  const [isVisitEventLogged, setIsVisitEventLogged] = useState(false);
  const [isPrevDetailAddressEmpty, setIsPrevDetailAddressEmpty] = useState(true);
  const scrollPosition = useScrollPosition();
  const { hashId } = useParams<{ hashId: string }>();

  const { data: car, isLoading } = useQuery({
    queryKey: ["car", hashId],
    queryFn: createGetCarAPI(hashId),
  });

  const { mutateAsync: patchCarPreInspection, isLoading: isPatchCarPreInspectionLoading } = useMutation({
    mutationFn: createPatchCarPreInspectionAPI(hashId),
  });

  const onDetailAddressChange: ChangeEventHandler<HTMLTextAreaElement> = ({ target: { value } }) => {
    setDetailAddress(value.slice(0, MAX_LENGTH));
  };

  const onCTAClick = async () => {
    if (isPrevDetailAddressEmpty && !detailAddress) {
      toast.default("내용을 입력해주세요");

      return;
    }

    await patchCarPreInspection({ detail_address: detailAddress });

    toast.success(`${isPrevDetailAddressEmpty ? "입력" : "수정"}이 완료되었습니다`);
    close();
  };

  useEffect(() => {
    if (car) {
      if (isAppWebview && !isVisitEventLogged) {
        logVisitDetailAddressFormEvent(hashId, car.status);
        setIsVisitEventLogged(true);
      }

      setDetailAddress(car.pre_inspection.detail_address ?? "");
      setIsPrevDetailAddressEmpty(!car.pre_inspection.detail_address);
    }
  }, [car]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Page>
      {isAppWebview && (
        <AppBar
          leftIcon={{ icon: StrokeCloseIcon, onClick: close }}
          fixed
          background={scrollPosition <= 0 ? "transparent" : `${colors.white_1}E6`}
        />
      )}
      <img css={{ width: "100%" }} src={DetailAddressBg} />
      <div css={{ display: "grid", gap: "0.75rem", padding: "1.5rem" }}>
        <Typography variant="headline_3">평가사에게 알려주세요</Typography>
        <Typography variant="body_1">
          주차 위치 또는 아파트 출입에 필요한 정보를 <br />
          미리 알려주시면, 평가 시간을 단축할 수 있어요.
        </Typography>
      </div>
      <Divider />
      <div css={{ display: "grid", gap: "0.5rem", padding: "1.5rem" }}>
        <div css={{ display: "grid", gap: "1rem" }}>
          <div css={{ display: "grid", alignItems: "center", gap: "0.25rem", gridTemplateColumns: "1rem 1fr" }}>
            <LocationIcon size={16} color="black_6" />
            <Typography variant="body_2">{car?.pre_inspection.address}</Typography>
          </div>
          <TextareaTextfield
            size={56}
            height={120}
            value={detailAddress}
            onChange={onDetailAddressChange}
            placeholder={"ex) 아이파크 201동 지하주차창 2층, 경비실에 201동에 방문한다고 말씀 주시면 돼요."}
          />
        </div>
        <div css={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant="caption_1" color="black_6">
            {detailAddress.length}/{MAX_LENGTH}
          </Typography>
        </div>
      </div>
      <div css={CTAAreaStyle}>
        <Button size={56} variant="primary" onClick={onCTAClick} fullWidth loading={isPatchCarPreInspectionLoading}>
          {isPrevDetailAddressEmpty ? "입력" : "수정"}완료
        </Button>
      </div>
    </Page>
  );
};

export default PreInspectionDetailAddressPage;
