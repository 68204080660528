import { logEvent } from "@PRNDcompany/heydealer-lib/app";

type LogVisitChronicProblemsEventParams = {
  model_hash_id: string;
  referrer: string;
  car_photo_detail?: string;
  total_info_detail?: string;
};

export const logVisitChronicProblemsEvent = (parameters: LogVisitChronicProblemsEventParams) => {
  logEvent({
    event_name: "visit_chronic_problems",
    event_description: "고질병 목록 페이지 진입",
    parameters,
    provider: { api: true, firebase: true, appsflyer: false, facebook: false },
  });
};
