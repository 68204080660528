/** @jsxImportSource @emotion/react */
import type { FC } from "react";
import { useEffect, useState } from "react";

import { css } from "@emotion/react";
import { close } from "@PRNDcompany/heydealer-lib/app";
import {
  DeprecatedAppBar as AppBar,
  Button,
  colors,
  FilterChip,
  NoItem,
  SearchBar,
  SpeechIcon,
  StrokeCloseIcon,
  TitleBar,
  Typography,
} from "@PRNDcompany/heydealer-ui";
import { useQuery } from "@tanstack/react-query";
import { useHistory, useLocation } from "react-router";

import Page from "../../components/Page";
import remToPx from "../../utils/remToPx";
import type { FAQ } from "../customer/apis";
import { getFAQsAPI } from "../customer/apis";

import DictionaryBanner from "./components/DictionaryBanner";
import FAQSection from "./components/FAQSection";
import { faqGroupsArray, faqGroupTextMap, faqTypeChipLabelMap, faqTypesArray } from "./constants";
import { highlightQuery, scoreFAQ } from "./utils";

const filterChipListWrapperStyle = css`
  display: grid;
  justify-content: start;
  gap: 0.5rem;

  position: relative;

  padding: 0.75rem 1.5rem;

  grid-auto-flow: column;

  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const isProperType = (type: string) => faqTypesArray.some((faqType) => type === faqType);

const FAQPage: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const type = params.get("type");

  const { data: faqs } = useQuery({
    queryKey: [`/v2/customers/faqs/?type=${type}`],
    queryFn: async () => (type ? await getFAQsAPI({ type }) : []),
    enabled: !!type && isProperType(type),
  });

  const [searchValue, setSearchValue] = useState("");

  const setType = (type: string) => {
    params.set("type", type);
    location.search = params.toString();
    history.replace(location);
  };

  const groupedFAQs = faqGroupsArray
    .map<[typeof faqGroupsArray[number], FAQ[]]>((faqGroup) => [
      faqGroup,
      faqs?.filter((faq) => faq.group === faqGroup) ?? [],
    ])
    .filter(([, group]) => group.length > 0);

  const filteredFAQs = faqs
    ?.map<[FAQ, number]>((faq) => [faq, scoreFAQ(faq, searchValue)])
    .filter(([, score]) => score > 0)
    .sort(([, a], [, b]) => (a > b ? -1 : 1))
    .map(([faq]) => ({
      ...faq,
      ...(searchValue && {
        title: highlightQuery(faq.title, searchValue),
        content: highlightQuery(faq.content, searchValue),
      }),
    }));

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [type]);

  if (!type || !isProperType(type)) {
    setType(faqTypesArray[0]);

    return null;
  }

  return (
    <Page title="자주 묻는 질문">
      <div css={{ position: "sticky", top: 0, background: colors.white_1, zIndex: 1 }}>
        <AppBar leftIcon={{ icon: StrokeCloseIcon, onClick: () => close() }} />
        <TitleBar title="자주 묻는 질문" />
        <div css={filterChipListWrapperStyle}>
          {faqTypesArray.map((faqType) => (
            <div
              key={faqType}
              onClick={({ currentTarget: { offsetParent, offsetLeft } }) => {
                setType(faqType);
                offsetParent?.scrollTo({ left: offsetLeft - remToPx(1.5), behavior: "smooth" });
              }}
            >
              <FilterChip checked={type === faqType} label={faqTypeChipLabelMap[faqType]} />
            </div>
          ))}
        </div>
        <SearchBar
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="궁금한 내용을 검색해보세요."
        />
      </div>
      <div>
        {searchValue.trim() ? (
          filteredFAQs && filteredFAQs.length > 0 ? (
            <div css={{ padding: "1.5rem" }}>
              {filteredFAQs.map((faq) => (
                <FAQSection key={faq.hash_id} {...faq} />
              ))}
            </div>
          ) : (
            <div css={{ display: "grid", padding: "2.5rem 0", gap: "0.625rem", justifyItems: "center" }}>
              <NoItem img="history" text={"찾으시는 질문이 없어요.\n채팅으로 문의주시면 답변 드릴게요."} />
              <Button
                size={48}
                variant="secondary"
                icon={SpeechIcon}
                onClick={() => (window.location.href = "heydealer://chat")}
              >
                채팅 문의하기
              </Button>
            </div>
          )
        ) : (
          faqs && (
            <>
              {groupedFAQs.map(([faqGroup, group]) => (
                <div css={{ padding: "1.5rem", display: "grid", gap: "0.5rem" }} key={faqGroup}>
                  {faqGroup in faqGroupTextMap && (
                    <Typography variant="subtitle_2" color="black_6">
                      {faqGroupTextMap[faqGroup] ?? faqGroup}
                    </Typography>
                  )}
                  {group.map((faq) => (
                    <FAQSection key={faq.hash_id} {...faq} />
                  ))}
                </div>
              ))}

              <div css={{ padding: "1rem 1.5rem", marginBlockStart: "-1.5rem" }}>
                <DictionaryBanner />
              </div>
            </>
          )
        )}
      </div>
    </Page>
  );
};

export default FAQPage;
