import { logEvent } from "@PRNDcompany/heydealer-lib/app";

import type { Car } from "../../types";

export type LogClickBoostFixedPriceZeroEventParams = {
  car_hash_id: Car["hash_id"];
  car_status: Car["status"];
  desired_price: number;
  result: "success" | "desired_price_too_low" | "desired_price_too_high";
};

export const logClickBoostFixedPriceZeroEvent = (params: LogClickBoostFixedPriceZeroEventParams) => {
  logEvent({
    event_name: "click_boost_fixed_price_zero",
    event_description: "(희망가 zero) 끌어올리기 버튼 클릭",
    parameters: {
      ...params,
      desired_price: params.desired_price.toString(),
    },
    provider: { api: true, firebase: true, appsflyer: false, facebook: false },
  });
};
