/** @jsxImportSource @emotion/react */
import type { FC } from "react";

import { close } from "@PRNDcompany/heydealer-lib/app";
import { DeprecatedAppBar as AppBar, colors, StrokeCloseIcon, TitleBar, Typography } from "@PRNDcompany/heydealer-ui";

import Page from "../../../components/Page";
import useScrollPosition from "../../../hooks/useScrollPosition";

import NewsBG from "./assets/bg/news.png";

const MarketConditionPage: FC = () => {
  const scrollPosition = useScrollPosition();

  return (
    <Page>
      <AppBar
        fixed
        leftIcon={{ icon: StrokeCloseIcon, onClick: close }}
        background={scrollPosition <= 0 ? "transparent" : `${colors.white_1}E6`}
      />
      <header>
        <img src={NewsBG} css={{ width: "100%" }} />
      </header>
      <div css={{ padding: "1.5rem 0" }}>
        <TitleBar title={"높은 금리가 시세에 \n왜 영향을 주나요?"} />
        <div css={{ padding: "1.5rem", display: "grid", gap: "2.5rem" }}>
          <div css={{ display: "grid", gap: "0.5rem" }}>
            <Typography variant="subtitle_1">
              딜러가 매입을 <br /> 줄이기 때문이에요.
            </Typography>
            <Typography variant="body_1">
              차를 매입할 때 자금을 대출 받는데요. <br />
              올해 초 4% 였던 금리가 최근 11% 대로 뛰면서 매입에 부담을 안거나, 대출 자체가 막히면서 매입을 더 할 수
              없는 상황이에요.
            </Typography>
          </div>
          <div css={{ display: "grid", gap: "0.5rem" }}>
            <Typography variant="subtitle_1">
              고객의 수요도 <br /> 낮아지기 때문이에요.
            </Typography>
            <Typography variant="body_1">
              고객들도 중고차를 구입할 때 할부를 이용해요. 최근 15%가 넘는 금리로 구매를 미루거나, 포기하는 고객이
              많아지면서 중고차 구매 수요가 줄어들고 있어요.
            </Typography>
          </div>
        </div>
      </div>
      <div css={{ padding: "1.5rem 0" }}>
        <TitleBar title={"앞으로 시세는 \n어떻게 될까요?"} />
        <div css={{ padding: "1.5rem", display: "grid", gap: "2.5rem" }}>
          <div css={{ display: "grid", gap: "0.5rem" }}>
            <Typography variant="subtitle_1">더 하락할 가능성이 높아요.</Typography>
            <Typography variant="body_1">
              금리는 더 오르고, 날씨가 추워지는 겨울은 중고차 시장 비수기예요. <br />
              또, 신차 대기 기간이 매우 길었던 특수상황으로 일시적으로 중고차 수요가 높아졌는데 최근 신차 대기 기간이
              다시 짧아지고 있어요.
            </Typography>
          </div>
          <div css={{ display: "grid", gap: "0.5rem" }}>
            <Typography variant="subtitle_1">
              고가 차량일수록 <br />
              하락 폭이 더 클 수 있어요.
            </Typography>
            <Typography variant="body_1">
              매월 나가는 할부 이자 부담으로, 특히 3000만원대 이상의 고가 차량 위주로 구매 수요가 많이 줄어들고 있어요.
            </Typography>
          </div>
          <div css={{ background: colors.brand_2_alpha_8, padding: "1rem" }}>
            <Typography variant="body_1" color="brand_2">
              높은 금리, 비수기 등이 겹치면서 당분간 시세가 더 낮아질 수 있어요.
            </Typography>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default MarketConditionPage;
