/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { css } from "@emotion/react";
import { close, isAppWebview } from "@PRNDcompany/heydealer-lib/app";
import { DeprecatedAppBar as AppBar, colors, StrokeCloseIcon, TitleBar, Typography } from "@PRNDcompany/heydealer-ui";

import Page from "../../components/Page";
import useScrollPosition from "../../hooks/useScrollPosition";
import useVideoBackground from "../../hooks/useVideoBackground";

import BgExpertImgVideo from "./assets/img/bg_expert.mp4";
import BgExpertImgPoster from "./assets/img/bg_expert.png";
import CarFrameImg from "./assets/img/car_frame.png";
import CarInsideImg from "./assets/img/car_inside.png";
import CarOutsideImg from "./assets/img/car_outside.png";
import CarScratchImg from "./assets/img/car_scratch.png";
import FileImg from "./assets/img/file.png";
import GraphImg from "./assets/img/graph.png";
import ReportImg from "./assets/img/report.png";
import ChecklistItem from "./components/ChecklistItem";

const boxStyle = css`
  display: grid;
  gap: 0.25rem;

  padding: 0.75rem;

  border: 1px solid ${colors.white_4};
  border-radius: 0.25rem;

  background: ${colors.white_2};

  align-content: start;
`;

const sectionWrapperStyle = css`
  display: grid;
  gap: 1.5rem;

  justify-items: center;
`;

const sectionImgStyle = css`
  height: 7.5rem;
`;

const checklistStyle = css`
  display: grid;
  gap: 0.5rem;

  width: 100%;
`;

const InspectionPointPage: FC = () => {
  const scrollPosition = useScrollPosition();
  const { background, videoRef, canvasRef } = useVideoBackground(colors.white_4);

  return (
    <Page>
      {isAppWebview && (
        <AppBar
          leftIcon={{ icon: StrokeCloseIcon, onClick: close }}
          background={scrollPosition <= 0 ? "transparent" : `${colors.white_1}E6`}
          fixed
        />
      )}
      <div css={{ background, display: "flex", justifyContent: "center" }}>
        <canvas css={{ height: "15rem" }} ref={canvasRef} />
        <video ref={videoRef} autoPlay muted playsInline hidden src={BgExpertImgVideo} poster={BgExpertImgPoster} />
      </div>
      <TitleBar
        title={"평가사가\n무엇을 진단하나요?"}
        description={"중고차 시세 영향요소들을 확인하고,\n진단 내용을 바탕으로 경매에 올려드려요."}
      />
      <div css={{ padding: "2.5rem 1.5rem", display: "grid", gap: "3.75rem" }}>
        <div css={sectionWrapperStyle}>
          <img css={sectionImgStyle} src={FileImg} />
          <div css={checklistStyle}>
            <Typography variant="subtitle_2">차량의 제원정보를 한번 더 확인해요.</Typography>
            <div>
              <ChecklistItem>출고 등급과 최초등록일</ChecklistItem>
              <ChecklistItem>최근 주행거리 기록</ChecklistItem>
              <ChecklistItem>신차 출고시 추가한 옵션</ChecklistItem>
            </div>
          </div>
        </div>
        <div css={sectionWrapperStyle}>
          <img css={sectionImgStyle} src={CarOutsideImg} />
          <div css={checklistStyle}>
            <Typography variant="subtitle_2">고장과 정비 필요사항을 확인해요.</Typography>
            <div>
              <ChecklistItem>엔진 누유</ChecklistItem>
              <ChecklistItem>계기판 경고등</ChecklistItem>
              <ChecklistItem>엔진오일, 브레이크패드 등 소모품 상태</ChecklistItem>
            </div>
          </div>
        </div>
        <div css={sectionWrapperStyle}>
          <img css={sectionImgStyle} src={CarInsideImg} />
          <div css={checklistStyle}>
            <Typography variant="subtitle_2">주요 기능들의 정상 작동을 확인해요.</Typography>
            <div>
              <ChecklistItem>에어백</ChecklistItem>
              <ChecklistItem>에어컨, 히터</ChecklistItem>
              <ChecklistItem>주요 옵션</ChecklistItem>
            </div>
          </div>
        </div>
        <div css={sectionWrapperStyle}>
          <img css={sectionImgStyle} src={CarScratchImg} />
          <div css={checklistStyle}>
            <Typography variant="subtitle_2">눈에 보이는 관리 상태를 확인해요.</Typography>
            <div>
              <ChecklistItem>외판 스크래치, 휠 기스 등 외관상의 손상</ChecklistItem>
              <ChecklistItem>시트 손상, 냄새 등 실내 상태</ChecklistItem>
              <ChecklistItem>타이어 상태</ChecklistItem>
            </div>
          </div>
        </div>
        <div css={sectionWrapperStyle}>
          <img css={sectionImgStyle} src={CarFrameImg} />
          <div css={checklistStyle}>
            <Typography variant="subtitle_2">과거 사고로 인한 수리한 부위를 확인해요.</Typography>
            <div>
              <ChecklistItem>외판의 교환 또는 판금 이력</ChecklistItem>
              <ChecklistItem>주요 골격의 교환 또는 판금 이력</ChecklistItem>
            </div>
          </div>
        </div>
        <div css={{ display: "grid", gap: "1rem" }}>
          <Typography variant="subtitle_2">진단 후에는 어떻게 되나요?</Typography>
          <div css={{ display: "grid", gap: "1rem", gridTemplateColumns: "repeat(2, 1fr)" }}>
            <div css={boxStyle}>
              <img src={ReportImg} css={{ width: "3rem" }} />
              <Typography variant="body_2" customCSS={{ wordBreak: "keep-all" }}>
                진단결과를 바탕으로 경매에 올려드려요.
              </Typography>
            </div>
            <div css={boxStyle}>
              <img src={GraphImg} css={{ width: "3rem" }} />
              <Typography variant="body_2" customCSS={{ wordBreak: "keep-all" }}>
                딜러들이 48시간&nbsp;동안 <br /> 경매에 입찰해요.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default InspectionPointPage;
