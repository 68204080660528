/** @jsxImportSource @emotion/react */
import type { ElementRef, FC, MouseEventHandler } from "react";

import { css } from "@emotion/react";
import { isAppWebview, open } from "@PRNDcompany/heydealer-lib/app";
import { ArrowRightIcon, colors, Typography } from "@PRNDcompany/heydealer-ui";

import bookImg from "./assets/img/book.png";

const DICTIONARY_URL = "https://dict-heydealer.oopy.io/";

const bannerStyle = css`
  all: unset;

  display: grid;
  align-items: center;

  padding: 0.75rem 1rem;

  border-radius: 0.25rem;

  color: ${colors.brand_2};

  cursor: revert;

  grid-template-columns: 1fr auto;
  background-color: ${colors.brand_2_alpha_8};
`;

const DictionaryBanner: FC = () => {
  const handleClick: MouseEventHandler<ElementRef<"a">> = (event) => {
    if (!isAppWebview) {
      return;
    }

    event.preventDefault();

    open({ url: event.currentTarget.href, toolbar: true, full_screen: false });
  };

  return (
    <a href={DICTIONARY_URL} onClick={handleClick} target="_blank" css={bannerStyle}>
      <div css={{ display: "grid", gap: "0.5rem", gridTemplateColumns: "2rem 1fr", alignItems: "center" }}>
        <img src={bookImg} css={{ width: "100%" }} />
        <Typography variant="subtitle_3">내차팔기 백과사전</Typography>
      </div>
      <ArrowRightIcon size={16} />
    </a>
  );
};

export default DictionaryBanner;
