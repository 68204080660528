/** @jsxImportSource @emotion/react */

import type { FC } from "react";
import React, { useLayoutEffect } from "react";

import { close, isAppWebview, setStatusBar } from "@PRNDcompany/heydealer-lib/app";
import { DeprecatedAppBar as AppBar, colors, StrokeCloseIcon } from "@PRNDcompany/heydealer-ui";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router";
import { ClipLoader } from "react-spinners";

import Page from "../../components/Page";
import useScrollPosition from "../../hooks/useScrollPosition";

import { createGetCarAPI } from "./apis/cars";
import Progress from "./components/Progress";
import QuestionItem from "./components/QuestionItem";
import ReductionCenterSection from "./components/ReductionCenterSection";
import ReductionReview from "./components/ReductionReview";

const ReductionPage: FC = () => {
  const scrollPosition = useScrollPosition();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const carHashId = params.get("car_hash_id");

  const { data: car, isFetching } = useQuery({
    queryKey: ["cars", carHashId],
    queryFn: createGetCarAPI(carHashId!),
    enabled: !!carHashId,
  });

  useLayoutEffect(() => {
    if (isAppWebview) {
      setStatusBar({
        color: scrollPosition > 0 || carHashId ? colors.white_1 : colors.white_3,
        content_brightness: "dark",
      });
    }
  }, [carHashId, scrollPosition]);

  const { reduction_review_manager } = car?.trade ?? {};
  const isManagerVisible = !!reduction_review_manager;

  if (isFetching) {
    return (
      <Page background="white_1">
        <div css={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
          <ClipLoader color={colors.brand_2} />
        </div>
      </Page>
    );
  }

  return (
    <Page background={isManagerVisible ? "white_1" : "white_3"}>
      {isAppWebview && (
        <AppBar
          leftIcon={{ icon: StrokeCloseIcon, onClick: close }}
          sticky
          background={scrollPosition <= 0 ? "transparent" : `${colors.white_1}E6`}
        />
      )}
      {isManagerVisible ? (
        <ReductionReview reductionReviewManager={reduction_review_manager} />
      ) : (
        <ReductionCenterSection />
      )}
      <Progress />
      <div css={{ padding: "2rem 1.5rem", background: colors.white_3 }}>
        <QuestionItem
          title="딜러가 가격을 조율하면, 거래를 취소해도 되나요?"
          contentParagraphs={[
            "마지막까지 거래 여부는 고객님 자유입니다. 정중히 딜러분께 거래 보류나 취소를 말씀해주세요. 어떤 비용도 없습니다.",
            "만약 딜러 요구로 어쩔 수 없이 출장비나 교통비를 지급하셨다면, 꼭 알려주세요. 헤이딜러에서 돌려드리겠습니다.",
          ]}
        />
      </div>
    </Page>
  );
};

export default ReductionPage;
