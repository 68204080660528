/** @jsxImportSource @emotion/react */

import type { FC } from "react";

import { css } from "@emotion/react";
import { isAppWebview, open } from "@PRNDcompany/heydealer-lib/app";
import { Button, colors, Haptic, Typography } from "@PRNDcompany/heydealer-ui";

import DotLine from "../../../components/DotLine";
import Task from "../../../components/Task";
import IngTaskImg1 from "../assets/img/ingTaskImg1.png";
import IngTaskImg2 from "../assets/img/ingTaskImg2.png";

const previousSteps = ["직접 경매 올리기", "48시간 경매", "딜러와 거래 완료"];

const previousStepWrapperStyle = css`
  display: grid;
  align-items: center;
  gap: 0.5rem;

  height: 2rem;

  grid-template-columns: 1.5rem 1fr;
`;

const Progress: FC = () => (
  <div css={{ padding: "2.5rem 1.5rem", background: colors.white_1, display: "grid" }}>
    {previousSteps.map((title) => (
      <div key={title} css={{ display: "grid", justifyContent: "start" }}>
        <div css={previousStepWrapperStyle}>
          <Task variant="todo" />
          <Typography variant="body_1" color="white_6">
            {title}
          </Typography>
        </div>
        <div css={{ display: "grid", gap: "0.25rem", width: "1.5rem", justifyContent: "center" }}>
          {[...Array(4)].map((_, index) => (
            <DotLine key={index} />
          ))}
        </div>
      </div>
    ))}
    <div css={{ display: "grid", gap: "0.5rem", gridTemplateColumns: "1.5rem 1fr" }}>
      <div css={{ paddingTop: "0.25rem", display: "grid", gap: "0.5rem" }}>
        <Task variant="ing" />
        <div css={{ display: "grid", gap: "0.25rem", width: "1.5rem", justifyContent: "center" }}>
          {[...Array(61)].map((_, index) => (
            <DotLine key={index} />
          ))}
        </div>
      </div>
      <div css={{ display: "grid", gap: "0.5rem", alignContent: "start" }}>
        <Typography variant="subtitle_2" color="brand_2">
          딜러가 현장감가 내역·사진 제출
        </Typography>
        <Typography variant="body_1">
          현장감가 발생으로 최초 입찰가와 거래가격이 다를 경우, 딜러가 헤이딜러 본사에 감가 내역과 사진을 제출해야
          합니다.
        </Typography>
        <img src={IngTaskImg1} css={{ width: "100%", maxWidth: "17.5rem" }} />
      </div>
    </div>
    <div css={{ display: "grid", gap: "0.5rem", gridTemplateColumns: "1.5rem 1fr" }}>
      <div css={{ paddingTop: "0.25rem", display: "grid", gap: "0.5rem" }}>
        <Task variant="ing" />
        <div css={{ display: "grid", gap: "0.25rem", width: "1.5rem", justifyContent: "center" }}>
          {[...Array(64)].map((_, index) => (
            <DotLine key={index} />
          ))}
        </div>
      </div>
      <div css={{ display: "grid", gap: "0.5rem", alignContent: "start" }}>
        <Typography variant="subtitle_2" color="brand_2">
          현장감가 검토
        </Typography>
        <Typography variant="body_1">
          딜러가 올린 사진으로 감가가 필요한 사항인지 확인합니다. 판단이 어려운 경우 정비 내역서를 추가로 확인합니다.
        </Typography>
        <img src={IngTaskImg2} css={{ width: "100%", maxWidth: "17.5rem" }} />
        <Haptic type="interact" trigger="onClick">
          <Button
            variant="secondary"
            size={48}
            color="navy"
            onClick={() => {
              if (isAppWebview) {
                open({ url: new URL("/reduction-standard", window.location.origin).toString() });
              } else {
                window.open("/reduction-standard", "_blank");
              }
            }}
          >
            감가기준 보기
          </Button>
        </Haptic>
      </div>
    </div>
    <div css={{ display: "grid", gap: "0.5rem", gridTemplateColumns: "1.5rem 1fr" }}>
      <div css={{ paddingTop: "0.25rem", display: "grid", gap: "0.5rem" }}>
        <Task variant="ing" />
      </div>
      <div css={{ display: "grid", gap: "0.5rem", alignContent: "start" }}>
        <Typography variant="subtitle_2" color="brand_2">
          부당 감가 환급
        </Typography>
        <Typography variant="body_1">
          부당감가로 판단되면 딜러에게 환급을 요청합니다. 딜러가 환급을 거부할 경우, 딜러 계정은 정지하고 헤이딜러에서
          부당감가를 돌려드립니다.
        </Typography>
        <img src={IngTaskImg1} css={{ width: "100%", maxWidth: "17.5rem" }} />
      </div>
    </div>
  </div>
);

export default Progress;
